import { useState } from "react";
import Image from "next/image";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { useMedia } from "../../hooks/useMedia";
import { Arrow } from "../Arrow/Arrow";

import styles from "./Feedback.module.scss";
import { OrangePattern14x13, OrangePattern7x18 } from "./images";
import { FeedbackCard } from "./FeedbackCard";

const feedbacks = [
  {
    text: "I am so glad I found Raffine. School of etiqutee, elegance. I always felt like I was lacking in the social skills department and now that I have been taking courses with them, my confidence is sky-high! They offer great courses that are both affordable and effective. The cherry on top is that they are a woman-owned company which means they really understand the needs of their clients!",
    author: "Valentín Stefanija",
  },
  {
    text: "I had always been interested in the study of etiquette and elegance, but I never knew where to start. When I heard about this course, I was immediately intrigued. It was exactly what I needed! The instructors were so knowledgeable and helpful that not only did I learn a lot about etiquette, but also about myself.",
    author: "Jordana Carroll",
  },
  {
    text: "I had never heard of the school of etiqutee, elegance until I stumbled across their website. I was immediately drawn to its elegant design and the promise of improving my personality like a cherry on the top. It's been so amazing to have access to this course and it has helped me feel more confident in my day-to-day life.",
    author: "Celia Darío",
  },
  {
    text: "I have never been so happy to be in a class as I am when I am with Raffine. Schole of etiqutee, elegance. It's hard to find a company that really cares about their students and truly wants you to succeed. That's why I will always recommend this company to anyone who needs help with their personality and etiquette skills!",
    author: "Andrés Macaria",
  },
];

export function Feedback() {
  const { mobile } = useMedia();
  const [currentSlide, setCurrentSlide] = useState(0);

  const items = feedbacks.map((feedback) => {
    return <FeedbackCard text={feedback.text} author={feedback.author} key={feedback.author} />;
  });

  const prevPage = () => {
    if (currentSlide - 1 > -1) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const nextPage = () => {
    if (currentSlide + 1 < items.length) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const updateCurrentSlide = (index: number) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };
  const patternImage = mobile ? OrangePattern7x18 : OrangePattern14x13;

  return (
    <div className={styles.Container}>
      <div className={styles.Background}>
        <Image alt="Pattern" src={patternImage} width={2000} className={styles.PatternImage} />
      </div>
      <div className={styles.Content}>
        <Carousel
          centerMode
          centerSlidePercentage={100}
          infiniteLoop={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={currentSlide}
          onChange={updateCurrentSlide}
          showArrows={false}
          showThumbs={false}
          swipeScrollTolerance={20}
          preventMovementUntilSwipeScrollTolerance
        >
          {items}
        </Carousel>
        <div className={styles.ArrowsContainer}>
          <div className={styles.Arrows}>
            <div onClick={prevPage}>
              <Arrow direction="left" hidden={currentSlide == 0} />
            </div>

            <div onClick={nextPage}>
              <Arrow direction="right" hidden={currentSlide == feedbacks.length - 1} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
