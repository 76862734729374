import classNames from "classnames";

import { useTheme } from "foundation/Theme";
import { ThemesStyles } from "styles";

import styles from "./Text.module.scss";

interface Props {
  children: React.ReactNode;
  spacing?: "small" | "medium" | "large";
  type?: "accent";
}

export function Text({ children, type, spacing }: Props) {
  const theme = useTheme();
  return (
    <span
      className={classNames(styles.Text, {
        [styles[`Spacing--${spacing}`]]: spacing != null,
        [ThemesStyles[`Theme_${theme}--Accent`]]: type === "accent",
      })}
    >
      {children}
    </span>
  );
}
