import { Link } from "components/Link";

import styles from "./Footer.module.scss";

export const Footer = () => {
  return (
    <footer className={styles.Footer}>
      <div className={styles.Container}>
        <Link href="/privacy-policy">Privatumo politika</Link>
        <Link href="/terms-of-service">Paslaugų taisyklės</Link>
        <Link href="/return-policy">Grąžinimo taisyklės</Link>
      </div>
    </footer>
  );
};
