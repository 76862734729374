import classNames from "classnames";
import React from "react";

import styles from "./Form.module.scss";

interface Props {
  children: React.ReactNode | React.ReactNode[];
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const Form = React.forwardRef(({ children, onSubmit }: Props, ref: any) => {
  {
    return (
      <div className={styles.Form}>
        <form onSubmit={onSubmit} ref={ref} noValidate>
          <div className={styles.FormContent}>{children}</div>
        </form>
      </div>
    );
  }
});

interface FormItemProps {
  actions?: boolean;
}

const FormItem = ({ children, actions }: React.PropsWithChildren<FormItemProps>) => {
  return <div className={classNames(styles.Item, { [styles.Actions]: actions })}>{children}</div>;
};

Form.displayName = "Form";

export { Form, FormItem };
