import React, { useMemo } from "react";

import { Footer } from "components/Footer";
import { useNavigation } from "foundation/NavigationProvider";
import { Themes } from "styles";
import { ThemeProvider } from "foundation/Theme";

import { AboutEttiquete } from "../AboutEttiquete";
import { AboutMe } from "../AboutMe";
import { ContactUs } from "../ContactUs";
import { Courses } from "../Courses";
import { Feedback } from "../Feedback";
import { HeroSplit } from "../HeroSplit";
import { Page } from "../Page";
import { ItemType, SideMenu } from "../SideMenu";
import { WhyImportant } from "../WhyImportant";

import styles from "./Home.module.scss";

interface PageType {
  id: string;
  label: string;
  component: React.FC;
  theme: Themes;
  fullHeight?: boolean;
  excludeFromMenu?: boolean;
}

const pages = [
  {
    id: "Hero",
    label: "Pagrindinis",
    component: HeroSplit,
    theme: "cream",
    fullHeight: true,
    excludeFromMenu: true,
  },
  {
    id: "AboutEttiquete",
    label: "Kas yra etiketas?",
    component: AboutEttiquete,
    theme: "cream",
  },
  {
    id: "WhyImportant",
    label: "Kodėl verta jį išmokti?",
    component: WhyImportant,
    theme: "cream",
  },
  {
    id: "Courses",
    label: "Kursai ir pamokos",
    component: Courses,
    payloadKey: "courses",
    theme: "darkGreen",
  },
  {
    id: "Feedback",
    label: "Atsiliepimai",
    component: Feedback,
    theme: "pink",
    fullHeight: true,
  },
  {
    id: "AboutMe",
    label: "Apie mane",
    component: AboutMe,
    theme: "darkGreen",
  },
  {
    id: "ContactUs",
    label: "Susisiekite",
    component: ContactUs,
    theme: "cream",
  },
] as PageType[];

function Home() {
  const { activePage, goToPage, registerPageRef } = useNavigation();

  const handlePageChange = (pageId: string) => () => {
    goToPage(pageId);
  };

  const menuItems = pages
    .map(({ id, label, excludeFromMenu }) => {
      if (excludeFromMenu) {
        return;
      }

      return {
        id,
        label,
        onClick: handlePageChange(id),
        active: activePage === id,
      };
    })
    .filter(Boolean) as ItemType[];

  const pagesComponents = useMemo(() => {
    return pages.map(({ id, theme, fullHeight, component: Component }, index) => {
      const ref = React.createRef<HTMLDivElement>();
      registerPageRef({ field: id, value: ref });

      return (
        <Page key={id} borders={{ bottom: index !== pages.length - 1}} backgroundColor={theme} fullHeight={fullHeight} ref={ref}>
          <Component />
        </Page>
      );
    });
  }, [registerPageRef]);

  return (
    // <StaticPageProvider initialValue={<LandingPage />}>
    <div className={styles.Container}>
      <ThemeProvider theme="cream">
        <SideMenu items={menuItems} />
      </ThemeProvider>
      {pagesComponents}
      <ThemeProvider theme="cream">
        <Footer />
      </ThemeProvider>
    </div>
    // </StaticPageProvider>
  );
}

export default Home;
