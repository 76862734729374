import { TripleSplit } from "../TripleSplit";

import styles from "./AboutEttiquete.module.scss";

export function AboutEttiquete() {
  const rightSide = (
    <div className={styles.Container}>
      <p>
        Tai sudėtingas, kompleksinis reiškinys, kurį galima labai parastai apibūdinti, tarkim, vienos seniausių ir
        tebegyvuojančių pasaulio religijų – Zoroastrizmo – pagrindiniu principu ir moto:
      </p>
      <blockquote>geros mintys, geri žodžiai, geri darbai!</blockquote>
      <p>
        Etiketo sąvokos provokuojamo lūkesčio užtikrinimui pakaktų laikytis paprastos šiuo principu grįstos sekos, kurios
        išdava būtų gerai apgalvoti ir subalansuoti žodžiai bei veiksmai, kuriantys situatyviai adekvatų ir deramą
        elgesį bei laikyseną.
      </p>
      <p>
        Sakysite, o kuo čia dėta religija? Kulto reikalai etiketo pasauliui svetimi, bet objektas tas pats – žmogus ir
        jo santykių su aplinka kuriamas elgesys ... o idealėjanti elgsena veda link kulto ir religijoje, ir gerų manierų
        pasaulyje. Daugelis iš mūsų čia dėtų <span className={styles.Stop}>STOP</span> ir klaustų – o kam man tai? Kas
        iš to?
      </p>
      <p>
        Tai aktualūs ir pagrįsti klausimai, kuriuos lemia žemiški reiškiniai, vienodai svarbūs asmeniniame, profesiniame
        ir viešajame gyvenime.
      </p>
    </div>
  );

  return (
    <TripleSplit
      header="Kas yra etiketas?"
      leftSide="Etiketas – tai kompleksinis reiškinys, kurį galima apibūdinti kaip: geros mintys, geri žodžiai, geri darbai."
      rightSide={rightSide}
    />
  );
}
