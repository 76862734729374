import React, { useContext, useReducer, useState } from "react";

import scrollToElement from "src/utils/scrollToElement";

interface Props {
  children: React.ReactNode;
}

type PageRef = React.RefObject<HTMLDivElement>;

interface PageRefAction {
  field: string;
  value: PageRef;
}

type NavigationContextValue = {
  activePage?: string | null;
  goToPage: (page: string) => void;
  registerPageRef: React.Dispatch<PageRefAction>;
};

export const NavigationContext = React.createContext<NavigationContextValue>({
  activePage: null,
  goToPage: () => {},
  registerPageRef: () => {},
});

function pagesRefReducer(state: Record<string, PageRef>, action: PageRefAction) {
  return { ...state, [action.field]: action.value };
}

export function useNavigation() {
  const context = useContext(NavigationContext);

  if (context === undefined) {
    throw new Error("useNavigation must be used within a NavigationProvider");
  }

  return context;
}

export function NavigationProvider({ children }: Props) {
  const [activePage, setActivePage] = useState<string | null>();
  const [pageRefs, dispatch] = useReducer(pagesRefReducer, {});

  const goToPage = (pageId: string) => {
    setActivePage(pageId);
    scrollToElement(pageRefs[pageId]?.current);
  };

  return (
    <NavigationContext.Provider value={{ activePage, goToPage, registerPageRef: dispatch }}>
      {children}
    </NavigationContext.Provider>
  );
}
