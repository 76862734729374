import React from "react";

import styles from "./Checkbox.module.scss";

interface Props {
  name: string;
  label: React.ReactNode;
  checked?: boolean;
  onChange?: (value: boolean) => void;
  error?: string;
  required?: boolean;
}

export function Checkbox({ name, checked, label, error, required, onChange }: Props) {
  const onValueChange = (event: React.FormEvent<HTMLInputElement>) => {
    onChange && onChange(event.currentTarget.checked);
  };

  const errorMarkup = error && <div className={styles.Error}>{error}</div>;

  return (
    <div className={styles.Container}>
      <label htmlFor={name} className={styles.Label}>
        <input required={required} name={name} type="checkbox" onChange={onValueChange} checked={checked} />
        <span className={styles.Checkmark}></span>
        {label}
        {errorMarkup}
      </label>
    </div>
  );
}
