import styles from "./FeedbackCard.module.scss";


interface Props {
  text: string;
  author: string;
}

export function FeedbackCard({ text, author }: Props) {
  return <div className={styles.Container}>
    <div className={styles.Text}>{'"'}{text}{'"'}</div>
    <div className={styles.Author}>{author}</div>
  </div>;
}
