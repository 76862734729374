import React, { createContext, useContext } from "react";

import { Product } from "types/schema";

export const ProductsContext = createContext<{ products?: Product[] }>({});

interface Props {
  children: React.ReactNode | React.ReactNode[];
  products?: Product[];
}

export const ProductsProvider = ({ children, products }: Props) => {
  return <ProductsContext.Provider value={{ products }}>{children}</ProductsContext.Provider>;
};

export const useProducts = () => {
  const context = useContext(ProductsContext);

  if (context === undefined) {
    throw new Error("useProducts must be used within a ProductsProvider");
  }

  return context.products;
};
