import classNames from "classnames";
import React from "react";

import styles from "./TextField.module.scss";

interface Props {
  label?: React.ReactNode;
  value: string;
  name: string;
  onChange?: (value: string) => void;
  type: React.HTMLInputTypeAttribute | undefined;
  error?: string;
  required?: boolean;
}

export function TextField({ label, name, required, value, type, error, onChange }: Props) {
  const onValueChange = (event: React.FormEvent<HTMLInputElement>) => {
    onChange && onChange(event.currentTarget.value);
  };

  const labelMarkup = label && (
    <div className={classNames(styles.Label, { [styles.Hidden]: value == "" })}>
      <label>{label}</label>
    </div>
  );

  const errorMarkup = error && <div className={styles.Error}>{error}</div>;
  const input = (
    <input
      className={classNames(styles.Input, { [styles.InputError]: error != null })}
      type={type}
      value={value}
      onChange={onValueChange}
      placeholder={label as string}
      name={name}
      required={required}
    />
  );

  return (
    <div className={styles.Container}>
      {labelMarkup}
      {input}
      {errorMarkup}
    </div>
  );
}
