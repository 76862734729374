import classNames from "classnames";

import styles from "./Hamburger.module.scss";

interface Props {
  open: boolean;
  color?: 'orange';
  className?: string;
  onClick: () => void;
}

export function Hamburger({ onClick, open, className, color = 'orange'}: Props) {
  return (
    <div className={classNames(styles.Container, className, { [styles.Open]: open })} onClick={onClick}>
      <div className={classNames(styles.Line, styles[`Color_${color}`])}></div>
      <div className={classNames(styles.Line, styles[`Color_${color}`])}></div>
    </div>
  );
}
