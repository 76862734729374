import React, { useRef } from "react";

import { Page } from "components/Page";
import { ThemeProvider } from "foundation/Theme";
import { useModal } from "components/Modal";

import { Contact } from "../../../../types";
import { Button } from "../../../Button";
import { Checkbox } from "../../../Checkbox";
import { Form, FormItem, useForm } from "../../../Form";
import { Hamburger } from "../../../Hambuger";
import { Link } from "../../../Link";
import { useNotification } from "../../../Notification";
import { TextField } from "../../../TextField";

import styles from "./ContactModal.module.scss";

interface Props {
  productId?: string;
  topic?: string;
}

export function ContactModal({ productId, topic }: Props) {
  const { close } = useModal();
  const formRef = useRef<HTMLFormElement>(null);
  const { state, onFieldChange, errors, validate, reset } = useForm<Contact>({
    initialState: {
      name: "",
      phone: "",
      email: "",
      message: "",
      terms: false,
      target: "contact-course-modal",
      topic,
      productId,
    },
    formRef,
  });
  const { showMessage } = useNotification();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    await fetch("api/contact", {
      method: "POST",
      body: JSON.stringify(state),
    });

    showMessage({
      message: "Ačiū už jūsų žinutę! Mes susisieksime su jumis kuo greičiau.",
      hideAfter: 4000,
    });

    reset();
    close();
  };

  const termsLabel = (
    <>
      Sutinku su <Link href="#">privatumo politika</Link>
    </>
  );

  const title = topic ? `Užklausa apie "${topic}"` : "Susiekti su mumis";

  return (
    <ThemeProvider theme="cream">
      <Page>
        <div className={styles.Container}>
          <div className={styles.Header}>
            <div className={styles.Title}>
              <h4>{title}</h4>
            </div>
            <div className={styles.Action}>
              <Hamburger open onClick={close} />
            </div>
          </div>
          <div className={styles.Content}>
            <Form onSubmit={onSubmit} ref={formRef}>
              <div>
                <FormItem>
                  <TextField
                    required
                    name="name"
                    label="Jūsų vardas"
                    value={state.name}
                    onChange={onFieldChange("name")}
                    type="text"
                    error={errors.name}
                  />
                </FormItem>
                <FormItem>
                  <TextField
                    name="phone"
                    label="Tel. numeris"
                    value={state.phone}
                    onChange={onFieldChange("phone")}
                    type="text"
                    error={errors.phone}
                  />
                </FormItem>
                <FormItem>
                  <TextField
                    name="email"
                    label="El. paštas"
                    value={state.email}
                    onChange={onFieldChange("email")}
                    type="email"
                    required
                    error={errors.email}
                  />
                </FormItem>
                <FormItem>
                  <TextField
                    name="message"
                    label="Jūsų žinutė"
                    value={state.message}
                    onChange={onFieldChange("message")}
                    type="text"
                    error={errors.message}
                    required
                  />
                </FormItem>
              </div>
              <div>
                <FormItem actions>
                  <Checkbox
                    name="terms"
                    required
                    onChange={onFieldChange("terms")}
                    checked={state.terms}
                    label={termsLabel}
                    error={errors.terms}
                  />
                  <Button theme="orange">Siųsti</Button>
                </FormItem>
              </div>
            </Form>
          </div>
        </div>
      </Page>
    </ThemeProvider>
  );
}
