import Image from "next/image";

import { Button } from "components/Button";
import { useNavigation } from "foundation/NavigationProvider";

import styles from "./HeroSplit.module.scss";
import { Logo } from "./images";

export function HeroSplit() {
  const { goToPage } = useNavigation();
  return (
    <div className={styles.Container}>
      <div className={styles.Logo}>
        <Image width={200} src={Logo} alt="Raffine etiketo paslaptys" className={styles.LogoImage} />
      </div>
      <div className={styles.Headline}>
        <>Etiketas - sėkmės kalba, kuria mokau kalbėti!</>
        <div className={styles.CallToActions}>
          <Button responsive theme="orange" minWidth={200} onClick={() => goToPage('Courses')}>
            Kursai
          </Button>
          <Button responsive theme="sand" minWidth={200}  onClick={() => goToPage('AboutMe')}>
            Apie mane
          </Button>
        </div>
      </div>
    </div>
  );
}
