import Image from "next/image";
import Link from "next/link";

import { HighHeelsImage } from "assets";
import { Product, ProductType } from "types/schema";

import { Button } from "../../../Button";
import { useModal } from "../../../Modal";
import { ContactModal } from "../ContactModal";

import styles from "./ProductCard.module.scss";

interface Props {
  product: Product;
  number: number;
}

type ActionElement = ({ product }: { product: Product }) => JSX.Element;

const DefaultAction: ActionElement = ({ product }) => {
  return (
    <Link href={`product/${product.id}`}>
      <Button>Užsiregistruoti</Button>
    </Link>
  );
};

const SeminarActions: ActionElement = ({product}) => {
  const { show } = useModal();

  const modal = <ContactModal topic={product.title} />;

  const onClick = () => {
    show(modal);
  };

  return <Button onClick={onClick}>Susisiekti</Button>;
};

const actions: { [key in ProductType]?: ActionElement } = {
  [ProductType.SEMINAR]: SeminarActions,
};

export function ProductCard({ product, number }: Props) {
  const { title, description, type } = product;

  const Actions = actions[type] || DefaultAction;

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <div className={styles.Number}>#{number}</div>
        <div className={styles.Title}>
          <h4>{title}</h4>
        </div>
      </div>
      <div className={styles.Content}>
        <div className={styles.LeftSide}>
          <Image width={1734} src={HighHeelsImage} alt={title} className={styles.Image} />
        </div>
        <div className={styles.RightSide}>
          <div className={styles.Description}>
            <p>{description}</p>
          </div>
          <div className={styles.ProductType}>{type}</div>
          <div className={styles.Actions}>
            <Actions product={product} />
          </div>
        </div>
      </div>
    </div>
  );
}
