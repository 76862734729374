import React from "react";
import classNames from "classnames";

import styles from "./Link.module.scss";

interface Props {
  children: React.ReactNode;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  active?: boolean;
  className?: string;
  passiveUnderline?: boolean;
}

export function Link({ children, onClick, className, active, href, passiveUnderline = true }: Props) {
  return (
    <a
      className={classNames(styles.Link, className, {
        [styles.Active]: active,
        [styles.NoPasiveUnderline]: !passiveUnderline,
      })}
      onClick={onClick}
      href={href}
    >
      {children}
    </a>
  );
}
