import { useMemo } from "react";
import classNames from "classnames";

import { TulipPatternFirst, TulipPattern, TulipPatternLast } from "assets/patterns";
import { useTheme } from "foundation/Theme";
import { Themes, themeStyleName } from "styles";

import { useMedia } from "../../hooks/useMedia";

import styles from "./Pattern.module.scss";

interface Props {
  rows?: number;
  theme?: Themes;
}

const GRID_SIZES = {
  mobile: {
    rows: 2,
    columns: 5,
  },
  desktop: {
    rows: 2,
    columns: 6,
  },
  tablet: {
    rows: 2,
    columns: 7,
  },
};

export function Pattern({ theme,rows }: Props) {
  const { tablet, desktop } = useMedia();
  const globalTheme = useTheme();
  const finalTHeme = theme || globalTheme;

  const grid = useMemo(() => {
    let girdSize = GRID_SIZES.mobile;

    if (desktop) {
      girdSize = GRID_SIZES.desktop;
    } else if (tablet) {
      girdSize = GRID_SIZES.tablet;
    }

    if(rows) {
      girdSize = {
        ...girdSize,
        rows
      }
    }

    return new Array(girdSize.rows).fill(null).map((_, index) => {
      const columns = new Array(girdSize.columns).fill(null).map((_, index) => {
        if (index === 0)
          return (
            <div key={index} className={classNames(styles.Column, themeStyleName(finalTHeme, "Pattern"))}>
              <TulipPatternFirst />
            </div>
          );

        if (index === girdSize.columns - 1)
          return (
            <div key={index} className={classNames(styles.Column, themeStyleName(finalTHeme, "Pattern"))}>
              <TulipPatternLast />
            </div>
          );

        return (
          <div key={index} className={classNames(styles.Column, themeStyleName(finalTHeme, "Pattern"))}>
            <TulipPattern />
          </div>
        );
      });

      return (
        <div key={index} className={styles.Row}>
          {columns}
        </div>
      );
    });
  }, [desktop, tablet, finalTHeme]);

  return <div className={styles.Container}>{grid}</div>;
}
