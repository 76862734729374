import React from "react";

import { Link } from "../../Link";

import styles from "./Item.module.scss";

interface Props {
  children: React.ReactNode;
  onClick: () => void;
  active?: boolean;
}

export function Item({ children, onClick, active = false }: Props) {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    onClick();
  };

  return (
    <div className={styles.Container}>
      <Link active={active} className={styles.Link} onClick={handleClick} passiveUnderline={false}>
        {children}
      </Link>
    </div>
  );
}
