import React from "react";
import classNames from "classnames";

import { themeStyleName, Themes } from "styles";
import { useTheme } from "foundation/Theme";

import styles from "./Button.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: Themes;
  align?: "left" | "right" | "center";
  responsive?: boolean;
  minWidth?: number;
  priority?: "primary" | "secondary" | "default";
}

const PRIORITY_STYLE = {
  primary: "Button__primary",
  secondary: "Button__secondary",
  default: "Button",
};

export function Button({
  children,
  responsive,
  minWidth,
  theme: customTheme,
  className,
  align = "center",
  priority,
  ...rest
}: Props) {
  const themePriority = PRIORITY_STYLE[priority ?? "default"];

  const themeContextValue = useTheme();
  const theme = customTheme ?? themeContextValue;

  return (
    <button
      style={{
        minWidth: minWidth ?? 0,
      }}
      className={classNames(styles.Button, themeStyleName(theme, themePriority), styles[`Align__${align}`], className, {
        [styles.Responsive]: responsive,
        [styles.Disabled]: rest.disabled,
      })}
      {...rest}
    >
      {children}
    </button>
  );
}
