import { DoubleSplit } from "../DoubleSplit";

import { Me } from "./images";

export function AboutMe() {
  const body = (
    <>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia vitae earum, id, enim impedit quidem neque iste
        officia et omnis dolorem soluta ut quisquam mollitia blanditiis. Cumque expedita amet porro?
      </p>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Earum possimus veritatis ad consequuntur id suscipit
        consectetur eaque libero rerum neque, esse, cupiditate voluptatum aliquid illum soluta, architecto deleniti
        quidem ipsum!
      </p>
    </>
  );

  return <DoubleSplit theme="darkGreen" imgSrc={Me} title="Apie mane" rightSide={body} />;
}
