import React from "react";

import styles from "./TripleSplit.module.scss";

interface Props {
  header: string;
  leftSide: React.ReactNode;
  rightSide: React.ReactNode;
}

export function TripleSplit({ header, leftSide: leftText, rightSide: righText }: Props) {
  return (
    <div className={styles.Container}>
      <div className={styles.Header}>{header}</div>
      <div className={styles.Content}>
        <div className={styles.LeftSide}>
          <h3>{leftText}</h3>
        </div>
        <div className={styles.RightSide}>
          {righText}  
        </div>
      </div>
    </div>
  );
}
