import classNames from "classnames";

import { ArrowIcon } from "./images";
import styles from "./Arrow.module.scss";

interface Props {
  direction?: "left" | "right";
  hidden?: boolean;
}

export function Arrow({ direction = "left", hidden = false }: Props) {
  return (
    <ArrowIcon className={classNames(styles.Arrow, styles[`Arrow_${direction}`], { [styles.Hidden]: hidden })} />
  );
}
