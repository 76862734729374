import React, { createContext } from "react";
import classNames from "classnames";

import { Themes, ThemesStyles } from "styles";

interface Props {
  theme: Themes;
}

export const ThemeContext = createContext<Themes>("cream");

export const useTheme = () => React.useContext(ThemeContext);

export function ThemeProvider({ theme, children }: React.PropsWithChildren<Props>) {
  return (
    <ThemeContext.Provider value={theme}>
      <div className={classNames(ThemesStyles[`Theme_${theme}`])}>{children}</div>
    </ThemeContext.Provider>
  );
}
