import { HEADER_HEIGHT } from "src/constants";

export default function scrollToElement(element: HTMLDivElement | null) {
  if (!element) {
    return;
  }

  const headerOffset = HEADER_HEIGHT;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.scrollY - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}