import React, { useReducer, useState } from "react";

type ErrorMessages<S> = {
  [key in keyof S]?: string;
};

interface Action<S> {
  field: keyof S;
  value: string | boolean;
}

interface Props {
  formRef: React.RefObject<HTMLFormElement>;
}

export function useForm<S>({ initialState, formRef }: { initialState: S } & Props) {
  const [errors, setFormErrors] = useState<ErrorMessages<S>>({});

  const formReducer = (state: S, action: Action<S>): S => {
    return { ...state, [action.field]: action.value };
  };
  const [state, dispatch] = useReducer(formReducer, initialState);

  function reset() {
    for (const key in state) {
      if (typeof state[key] === "string") {
        dispatch({ field: key, value: "" });
      } else if (typeof state[key] === "boolean") {
        dispatch({ field: key, value: false });
      }
    }
  }

  const onFieldChange = (field: keyof S) => (value: string | boolean) => {
    dispatch({ field, value });
  };

  const validate = () => {
    const errors = Array.from((formRef.current?.elements || []) as HTMLInputElement[]).reduce((carry, element) => {
      const elementName: string = element.name;
      let errorMessage;

      if (element.validity.valueMissing) {
        errorMessage = "Privalomas laukas";
      } else if (element.validity.typeMismatch) {
        errorMessage = "Neteisingai suvesti duomenys";
      }

      const newCarry = errorMessage ? { ...carry, [elementName]: errorMessage } : carry;

      return newCarry;
    }, {});

    setFormErrors(errors);

    return Object.keys(errors).length == 0;
  };

  return { state, onFieldChange, errors, validate, reset };
}
