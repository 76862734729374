import classNames from "classnames";
import Image, { StaticImageData } from "next/image";
import React from "react";

import { useMedia } from "hooks/useMedia";

import styles from "./DoubleSplit.module.scss";

interface Props {
  imgSrc?: string | StaticImageData;
  imgAlt?: string;
  title?: string;
  leftSide?: React.ReactNode;
  rightSide: React.ReactNode;
  theme: "darkGreen" | "cream";
}

export function DoubleSplit({ imgSrc, leftSide, imgAlt, title, rightSide, theme }: Props) {
  const { mobile } = useMedia();

  const leftContent = imgSrc ? (
    <div className={styles.ImageWrapper}>
      <Image alt={imgAlt || "Image"} src={imgSrc} className={styles.Image} fill priority />
    </div>
  ) : (
    leftSide
  );

  return (
    <div className={classNames(styles.Container, styles[`Theme_${theme}`])}>
      <div className={styles.LeftSide}>{leftContent}</div>
      <div className={styles.RightSide}>
        {title && (
          <div className={styles.Title}>
            <h3>{title}</h3>
          </div>
        )}
        <div className={styles.Content}>{rightSide}</div>
      </div>
    </div>
  );
}
