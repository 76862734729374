import { useEffect, useState } from "react";

enum Screen {
  LARGE = 1296,
  MEDIUM = 990,
  SMALL = 672,
  X_SMALL = 415,
}

export function useMedia() {
  const [mobile, setMobile] = useState(true);
  const [tablet, setTablet] = useState(false);
  const [desktop, setDesktop] = useState(false);

  useEffect(() => {
    setDesktop(window.matchMedia(`(min-width: ${Screen.LARGE}px)`).matches);
    setTablet(window.matchMedia(`(min-width: ${Screen.SMALL}px) and (max-width: ${Screen.LARGE}px)`).matches);
    setMobile(window.matchMedia(`(max-width: ${Screen.SMALL}px)`).matches);
  }, []);

  return {
    desktop,
    tablet,
    mobile,
  };
}
