import { useMedia } from "../../hooks/useMedia";
import { DoubleSplit } from "../DoubleSplit";

import styles from "./WhyImportant.module.scss";
import { DinningTable, DinningTableVertical } from "./images";


export function WhyImportant() {
  const { mobile } = useMedia();

  const body = (
    <div className={styles.Container}>
      <ul>
        <li>Nes labiau pasitikėsite savimi</li>
        <li>Nes išvengsite streso</li>
        <li>Nes tobulėsite</li>
        <li>Nes susilauksite dėmesio ir pripažinimo</li>
        <li>Nes išvengsite kritikos</li>
        <li>Nes motyvuosiuositės ir motyvuosite</li>
        <li>Nes gerbsite ir būsite gerbiami</li>
      </ul>
    </div>
  );

  const imgSrc = mobile ? DinningTable : DinningTableVertical;

  return <DoubleSplit imgSrc={imgSrc} title="Kodėl verta išmanyti etiketą?" rightSide={body} theme="cream" />;
}
